import { ColorPaletteProp, SnackbarPropsColorOverrides } from "@mui/joy";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  OverridableStringUnion } from '@mui/types';

export type INotification = {
     message: string;
     milliseconds: number;
     color?: OverridableStringUnion<ColorPaletteProp, SnackbarPropsColorOverrides>;
}
export const DEFAULT_TIME = 2000;

const initialState: INotification = {
  message: "",
  milliseconds: DEFAULT_TIME,
  color: 'primary'
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notify: (state, action: PayloadAction<INotification>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { notify } = notificationSlice.actions;

export default notificationSlice.reducer;
