import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
export const ErrorPage: React.FC = () => {
  return (
    <>
      <Box>
        <Typography>Ех, сталась халепа!</Typography>
        <Link to="/">На головну</Link>
      </Box>
    </>
  );
};
