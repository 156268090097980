import { configureStore} from '@reduxjs/toolkit';
import logger from 'redux-logger';

import userReducer from './reducers/user.reducer';
import authReducer from './reducers/auth.reducer';
import loadingReducer from './reducers/loading.reducer';
import notificationReducer from './reducers/notification.reducer';
import globalLoaderReducer from './reducers/global-loading';


const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    loading: loadingReducer,
    notification: notificationReducer,
    globalLoader: globalLoaderReducer
  },
  //@ts-ignore have no clue some miss type issue
  middleware: (getDefaultMiddleware) => {
    if(process.env.REACT_APP_ENV === 'development'){
      return getDefaultMiddleware().concat(logger);
    }else{
      return getDefaultMiddleware();
    }
  }

});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
