import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalLoadingState {
  isLoading: boolean;
}

const initialState: GlobalLoadingState = {
  isLoading: false,
};

export const globalLoadingSlice = createSlice({
  name: "global-loading",
  initialState,
  reducers: {
    setGlobalLoading: (state, action: PayloadAction<GlobalLoadingState>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setGlobalLoading } = globalLoadingSlice.actions;

export default globalLoadingSlice.reducer;
