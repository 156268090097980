import { Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import { useTheme } from "@mui/joy/styles";

export const AppFooter: React.FC = () => {
  const theme = useTheme();
  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        borderTop: `1px solid ${theme.vars.palette.neutral[100]}`,
        display: { xs: "none", sm: "flex" },
        justifyContent: "center",
        position: "fixed",
        left: 0,
        bottom: 0,
        right: 0,
        padding: 1,
        zIndex: 10,
        background: `${theme.vars.palette.background.body}`,
        color: theme.vars.palette.text.primary,
      }}
    >
      <Typography level="title-sm">{`Copyright © NgS CodeLab 🥑 ${year}`}</Typography>
    </Box>
  );
};
