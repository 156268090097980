enum AppLocalStorageKeys {
  BEARER = "BEARER_TOKEN",
}

const setToLocalStorage = (key: string, value: unknown) =>
  localStorage.setItem(key, JSON.stringify(value));

const getFromLocalStorage = (key: string) => {
  const item = localStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : null;
  } catch (e) {
    console.log(e);
  }
};
const removeItemFromLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export type PaginationParams = {
  PAGE: number;
  ROWS: number;
  ORDER_BY: string;
  SEARCH_VALUE: string;
  DIRECTION: "ASC" | "DESC";
  PRODUCT_CATEGORY?: string;
};
const updatePaginationData = (params: PaginationParams) => {
  const {
    PAGE = 1,
    ROWS = 10,
    ORDER_BY,
    SEARCH_VALUE = "",
    DIRECTION = "ASC",
    PRODUCT_CATEGORY = "",
  } = params;
  const nextState = null;
  const nextURL = `${window.location.origin}${window.location.pathname}?PAGE=${PAGE}&ROWS=${ROWS}&ORDER_BY=${ORDER_BY}&SEARCH_VALUE=${SEARCH_VALUE}&DIRECTION=${DIRECTION}${PRODUCT_CATEGORY ? `&PRODUCT_CATEGORY=${PRODUCT_CATEGORY}`: ''}`;
  const nextTitle = "Pagination search";
  window.history.replaceState(nextState, nextTitle, nextURL);
};

export const DEFAULT_PRODUCT_SEARCH_PARAMS = {
  PAGE: 1,
  ROWS: 10000,
  DIRECTION: "ASC",
  SEARCH_VALUE: ""
};
const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const keys: Record<any, any> = {};
  if (searchParams.size) {
    //@ts-ignore
    for (const [key, value] of searchParams.entries()) {
      keys[key] = value;
    }
  }
  return keys;
};

const objectToSearchParams = (obj: any) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }

  return params;
};

export {
  setToLocalStorage,
  getFromLocalStorage,
  removeItemFromLocalStorage,
  AppLocalStorageKeys,
  updatePaginationData,
  getSearchParams,
  objectToSearchParams,
};
