import { createSlice,  PayloadAction } from "@reduxjs/toolkit";

interface LoadingState{
    isLoading: boolean;
}

const initialState: LoadingState = {
    isLoading: false
};


export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<LoadingState>) => {
      return {...state, ...action.payload};
    },
  },
});

export const { setLoading } = loadingSlice.actions;


export default loadingSlice.reducer;
