import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ReactNode, useEffect } from "react";
import { AppLocalStorageKeys, getFromLocalStorage } from "../../../utils/BOM";
import React from "react";
import {
  asyncGetUser,
  setUserInitialState,
} from "../../../store/reducers/user.reducer";
import { logOut } from "../../../store/reducers/auth.reducer";

export const AppRouteProtect: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  const navigate = useNavigate();
  const bearer = !!getFromLocalStorage(AppLocalStorageKeys.BEARER)?.length;

  useEffect(() => {
    if (!loggedIn && bearer) {
      dispatch(asyncGetUser());
    } else if (!loggedIn || !bearer) {
      dispatch(logOut());
      dispatch(setUserInitialState());
      return navigate("/sign");
    }
  }, [bearer, dispatch, loggedIn, navigate]);

  return <>{children}</>;
};
