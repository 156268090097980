import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import ExitIcon from "@mui/icons-material/ExitToApp";
import HomeIcon from "@mui/icons-material/Home";
import BrightnessAutoRoundedIcon from "@mui/icons-material/BrightnessAutoRounded";
import FilterVintageRoundedIcon from "@mui/icons-material/FilterVintageRounded";
import { closeSidebar, toggleSidebar } from "../../utils/side-bar";
import ColorSchemeToggle from "./ColorSchemToggle/ColorSchemToggle";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logOut } from "../../store/reducers/auth.reducer";
import { setUserInitialState } from "../../store/reducers/user.reducer";

export default function Sidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLogged = useAppSelector((state) => state.auth.loggedIn);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  return (
    <Sheet
      sx={{
        position: { xs: "fixed", md: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton variant="soft" color="primary" size="sm">
          <BrightnessAutoRoundedIcon />
        </IconButton>
        <Typography level="title-lg">Cvit Store</Typography>
        <ColorSchemeToggle
          sx={{
            visibility: { xs: "hidden", md: "visible" },
            ml: { xs: "none", md: "auto" },
          }}
        />
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        {isLogged && (
          <List
            size="sm"
            sx={{
              gap: 1,
              "--List-nestedInsetStart": "30px",
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
            }}
          >
            <ListItem
              onClick={() => {
                navigate("/");
                toggleSidebar();
              }}
            >
              <ListItemButton selected={pathname === "/home"}>
                <HomeIcon />
                <ListItemContent>
                  <Typography level="title-sm">Головна</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("/orders");
                toggleSidebar();
              }}
            >
              <ListItemButton selected={pathname === "/orders"}>
                <ShoppingCartRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Замовлення</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("/products");
                toggleSidebar();
              }}
            >
              <ListItemButton selected={pathname === "/products"}>
                <FilterVintageRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Товари</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </Box>
      <Divider />
      {isLogged && (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Avatar
            variant="outlined"
            size="sm"
            src="https://cdn-icons-png.flaticon.com/512/163/163847.png"
          />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm"> {user.firstName}</Typography>
            <Typography level="body-xs">{user.email}</Typography>
          </Box>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(logOut());
              dispatch(setUserInitialState());
              toggleSidebar();
            }}
          >
            <ExitIcon />
          </Box>
        </Box>
      )}
    </Sheet>
  );
}
