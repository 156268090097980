import axios from 'axios';
import { AppLocalStorageKeys, getFromLocalStorage } from '../../utils/BOM';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

// Add request interceptor
httpClient.interceptors.request.use(
  (config) => {
    const token = getFromLocalStorage(AppLocalStorageKeys.BEARER);
    if (token && config) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem(AppLocalStorageKeys.BEARER);
      delete httpClient.defaults.headers.common.Authorization;

      const event = new CustomEvent('unauthorized', {
        detail: {
          message: 'Unauthorized!',
        },
      });
      window.dispatchEvent(event);
    }
    return Promise.reject(error);
  }
);

export default httpClient;