import * as React from "react";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import { appRouter } from "../../../router";
import { Typography } from "@mui/joy";

type RouterWithNames = Array<RouteObject & { name?: string }>;

const findPageName = (router: RouterWithNames, seg: string) => {
  const matchingRoute = router.find(
    (r) =>
      r.path === `/${seg}` ||
      r?.children?.some((chldr) => chldr?.path === `/${seg}`)
  );
  return matchingRoute?.name;
};

export const AppBreadcrumbs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Breadcrumbs aria-label="breadcrumbs">
      {pathname
        .split("/")
        .filter((segment) => segment !== "")
        .map((segment) => {
          return (
            <Typography
              sx={{ cursor: "pointer" }}
              key={segment}
              onClick={() => navigate(segment)}
            >
              {findPageName(appRouter, segment)}
            </Typography>
          );
        })}
    </Breadcrumbs>
  );
};
