import httpClient from '../http-client';

export interface SignUpValues {
    email: string;
    password: string;
  }

interface AuthResponse {
    id: number,
    userUuid: string,
    firstName: string,
    lastName: string,
    email: string,
    role: 'admin' | 'regular',
    bearer: string,
}


export const users = {
  async createLocalUser(payload: SignUpValues)
  : Promise<{ status: boolean, data: { message: string & Omit<SignUpValues , 'password'>}} | null> {
    try {
      const response = await (await httpClient.post('users/local/sign-up', payload)).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async login(payload: SignUpValues)
  : Promise<{ status: boolean, data: {message: string, user: AuthResponse}} | null> {
    try {
      const result =  await (await httpClient.post('users/local/login', payload)).data;
      return result;
    } catch (e) {
      return null;
    }
  },
  async getUser()
  : Promise<{ status: boolean, data: {message: string, user: AuthResponse}} | null> {
    try {
      const result =  await (await httpClient.get('users/local/user/info')).data;
      return result;
    } catch (e) {
      return null;
    }
  },
  async activateMail(payload: {uuid: string})
  : Promise<{ status: boolean, data: {message: string, user: AuthResponse}} | null> {
    try {
      const result =  await (await httpClient.post('users/mail/activate', payload)).data;
      return result;
    } catch (e) {
      return null;
    }
  },
  async initPasswordReset(payload: {email: string})
  : Promise<{ status: boolean, data: {message: string}} | null> {
    try {
      const result =  await (await httpClient.post('users/local/password/reset', payload)).data;
      return result;
    } catch (e) {
      return null;
    }
  },
  async submitNewPassword(payload: {uuid: string, password: string})
  : Promise<{ status: boolean, data: {message: string}} | null> {
    try {
      const result =  await (await httpClient.post('users/local/password/change', payload)).data;
      return result;
    } catch (e) {
      return null;
    }
  }
};
export default users;