import { createSlice,  PayloadAction } from "@reduxjs/toolkit";
import { AppLocalStorageKeys, removeItemFromLocalStorage } from "../../utils/BOM";

export type UserRole = "admin" | "regular" | undefined;

interface AuthState {
    loggedIn: boolean;
    userRole: UserRole;
}

const initialState: AuthState = {
  loggedIn: false,
  userRole: undefined
};


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<AuthState>) => {
      return {...state, ...action.payload};
    },
    logOut: () => {
      removeItemFromLocalStorage(AppLocalStorageKeys.BEARER);
      return {...initialState};
    },
  },
});

export const { setLoggedIn, logOut } = authSlice.actions;


export default authSlice.reducer;
