
import userApi from './entities/user';
import productsApi from './entities/products';
import orders from './entities/orders';

const api = {
  userApi,
  productsApi,
  orders
};

export default api;