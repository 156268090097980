import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";

import Sidebar from "../@shared/Sidebar";
import { appRouter } from "../../router";
import Header from "../@shared/AppHeader/AppHeader";
import { CircLoader } from "../@shared/CircLoader/CircLoader";
import { AppFooter } from "../@shared/AppFooter/AppFooter";
import { AppBreadcrumbs } from "../@shared/AppBredcrumbs/AppBreadcrumbs";
import { AppNotification } from "../@shared/AppNotification/AppNotification";

export default function Main() {
  return (
    <Router>
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <Box sx={{ display: "flex", minHeight: "100dvh" }}>
          <Header />
          <Sidebar />
          <Box
            component="main"
            sx={{
              px: { xs: 2, md: 6 },
              pt: {
                xs: "calc(12px + var(--Header-height))",
                sm: "calc(12px + var(--Header-height))",
                md: 3,
              },
              pb: { xs: 2, sm: 5, md: 5 },
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              height: "100dvh",
              gap: 1,
              overflow: "auto",
            }}
          >
            <AppBreadcrumbs />
            <AppNotification />

            <React.Suspense fallback={<CircLoader />}>
              <Routes>
                {appRouter.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  >
                    {route.children?.map((r) => (
                      <Route key={r.path} path={r.path} element={r.element} />
                    ))}
                  </Route>
                ))}
              </Routes>
            </React.Suspense>
          </Box>
          <AppFooter />
        </Box>
      </CssVarsProvider>
    </Router>
  );
}
