import { objectToSearchParams } from "../../utils/BOM";
import httpClient from "../http-client";

export interface IProduct {
  productId: number;
  itemName: string;
  itemCategory: string;
  quantity: number;
  price: string;
  s3Path?: string;
  productCode: number;
}

export const products = {
  async getListOfProducts(params: Record<any, any>): Promise<{
    status: boolean;
    data: { products: Array<IProduct>; count: number };
  } | null> {
    try {
      const API_URL = Object.keys(params).length
        ? `products?${objectToSearchParams(params)}`
        : "products";
      const response = await (await httpClient.get(API_URL)).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async deleteProductById(
    id: string
  ): Promise<{
    status: boolean;
    data: { products: Array<IProduct>; message?: string };
  } | null> {
    try {
      const response = await (
        await httpClient.delete(`products/product/${id}`)
      ).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async updateProduct(payload: IProduct): Promise<{
    status: boolean;
    data: { affectedRows: number; message?: string };
  } | null> {
    try {
      const response = await (
        await httpClient.patch(`products/product/${payload.productId}`, payload)
      ).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async createProduct(payload: IProduct): Promise<{
    status: boolean;
    data: { insertId: number; message?: string };
  } | null> {
    try {
      const response = await (
        await httpClient.post(`products/product`, payload)
      ).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async getProductCategories(): Promise<{
    status: boolean;
    data: { categories: Array<string> };
  } | null> {
    try {
      const response = await (
        await httpClient.get(`products/categories`)
      ).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async addProductImage(payload: { productId: number; file: File }): Promise<{
    status: boolean;
    data: { pathToS3: string} | { message: string};
  } | null> {
    try {
      const formData = new FormData();
      formData.append("file", payload.file);
      const response = await (
        await httpClient.post(
          `products/product/product-image/${payload.productId}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
      ).data;
      return response;
    } catch (e) {
      return null;
    }
  },
};
export default products;
