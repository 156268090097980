import { Snackbar, Typography } from "@mui/joy";
import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/joy";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { notify } from "../../../store/reducers/notification.reducer";
import InfoIcon from "@mui/icons-material/Info";

export const DEFAULT_TIME = 2000;

export const AppNotification: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const notification = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();

  return (
    <Snackbar
      variant="soft"
      color={notification.color}
      anchorOrigin={{
        vertical: matches ? "bottom" : "top",
        horizontal: "center",
      }}
      open={Boolean(notification.message)}
      autoHideDuration={notification.milliseconds}
      onClose={() =>
        dispatch(notify({ milliseconds: DEFAULT_TIME, message: "" }))
      }
    >
      <InfoIcon />
      <Typography component="title">{notification.message}</Typography>
    </Snackbar>
  );
};
