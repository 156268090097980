import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import api from "../../api";
import { setLoggedIn } from "./auth.reducer";
import {
  AppLocalStorageKeys,
  removeItemFromLocalStorage,
  setToLocalStorage,
} from "../../utils/BOM";
import { setLoading } from "./loading.reducer";
import { notify } from "./notification.reducer";
import { setGlobalLoading } from "./global-loading";

export type UserRole = "admin" | "regular" | undefined;

interface UserState {
  id: number | null;
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  bearer?: string;
}

export const initialState: UserState = {
  id: null,
  userUuid: "",
  firstName: "",
  lastName: "",
  email: "",
  role: undefined,
};

export const asyncLogin = createAsyncThunk<
  UserState,
  { email: string; password: string },
  { dispatch: AppDispatch; state: RootState }
>("@users/login", async (payload, { dispatch }) => {
  try {
    dispatch(setLoading({ isLoading: true }));
    const response = await api.userApi.login(payload);
    dispatch(
      setLoggedIn({ userRole: response?.data.user.role, loggedIn: true })
    );
    dispatch(
      notify({
        message: "Вхід виконано",
        milliseconds: 2000,
        color: "success",
      })
    );
    setToLocalStorage(AppLocalStorageKeys.BEARER, response?.data.user.bearer);
    return response?.data.user as UserState;
  } catch (error) {
    dispatch(
      notify({
        message: "Упс, щось пішло не так, спробуйте ще раз",
        milliseconds: 2000,
        color: "warning",
      })
    );
    throw error;
  } finally {
    dispatch(setLoading({ isLoading: false }));
  }
});
export const asyncGetUser = createAsyncThunk<
  UserState,
  void,
  { dispatch: AppDispatch; state: RootState }
>("@users/getUser", async (payload, { dispatch }) => {
  try {
    dispatch(setGlobalLoading({isLoading: true}));
    dispatch(setLoading({ isLoading: true }));
    const response = await api.userApi.getUser();
    dispatch(
      setLoggedIn({ userRole: response?.data.user.role, loggedIn: true })
    );
    return response?.data.user as UserState;
  } catch (error) {
    dispatch(setLoggedIn({ userRole: undefined, loggedIn: false }));
    dispatch(
      notify({
        message: "Упс, щось пішло не так, спробуйте ще раз",
        milliseconds: 2000,
        color: "warning",
      })
    );
    removeItemFromLocalStorage(AppLocalStorageKeys.BEARER);
    throw error;
  } finally {
    dispatch(setLoading({ isLoading: false }));
    dispatch(setGlobalLoading({isLoading: false}));
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return { ...state, ...action.payload };
    },
    setUserInitialState: (state) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers(builder) {
    builder.addCase(asyncLogin.pending, (state, action) => {});
    builder.addCase(asyncLogin.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(asyncLogin.rejected, (state, action) => {
      // handle Error
      return { ...initialState };
    });
    builder.addCase(asyncGetUser.pending, (state, action) => {});
    builder.addCase(asyncGetUser.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export const { setUser, setUserInitialState } = userSlice.actions; // Remove 'login' from here

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
