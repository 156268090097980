import { objectToSearchParams } from "../../utils/BOM";
import httpClient from "../http-client";
import { IProduct } from "./products";

export type TOrderStatus = "created" | "pending" | "delivered" | "removed" | "cancel";

export const OrderStatus = {
  created: "Створено",
  pending: "В процесі",
  removed: "Видалено",
  delivered: "Доставлено",
  cancel: "Скасовано",
};

export interface IClient {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  deliveryAddress: string;
  orderStatus: TOrderStatus;
  email?: string;
  clientId?: number;
}

export const transformSingleOrderResponseToIOrder = (payload: {
  order: OrderResponse;
  products: Array<IProduct>;
}): IOrder => {
  return {
    client: {
      firstName: payload.order.firstName,
      lastName: payload.order.lastName,
      phoneNumber: payload.order.phoneNumber,
      email: payload.order.email,
      deliveryAddress: payload.order.address,
      orderStatus: payload.order.status,
      clientId: payload.order.clientId
    },
    products: payload.products.map(prd => ({
      itemCategory: prd.itemCategory,
      itemName: prd.itemName,
      quantity: prd.quantity,
      qty: prd.quantity,
      price: prd.price,
      productId: prd.productId,
      productCode: prd.productCode
    })),
  };
};

export const transformOrderContextDataToPayload = (order: IOrder) => ({
  client: {
    firstName: order.client.firstName,
    lastName: order.client.lastName,
    phoneNumber: order.client.phoneNumber,
    email: `${order.client.email}`,
    status: `${order.client.orderStatus}`,
    clientId: order.client.clientId
  },
  sendTo: order.client.deliveryAddress,
  items: order.products.map((o) => ({
    category: o.itemCategory,
    qty: o.qty as number,
    id: o.productId,
  })),
});

export type OrderFlow = "new" | "edit";

export type IProductOrder = IProduct & { qty?: number };

export interface IOrder {
  products: Array<IProductOrder>;
  client: IClient;
}

type OrderPayload = {
  client: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
  sendTo: string;
  items: Array<{
    id: number;
    qty: number;
    category: string;
  }>;
};

interface OrderResponse {
  orderId: number;
  clientId: number;
  orderDate: string;
  status: TOrderStatus;
  address: string;
  totalAmount: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export type OrdersPaginationResponse = {
  count: number;
  orders: Array<OrderResponse>;
};

export const orders = {
  async getOrdersWithPagination(params: Record<any, any>): Promise<{
    status: boolean;
    data: OrdersPaginationResponse;
  } | null> {
    try {
      const API_URL = Object.keys(params).length
        ? `orders?${objectToSearchParams(params)}`
        : "orders";
      const response = await (await httpClient.get(API_URL)).data;
      return response;
    } catch (e) {
      return null;
    }
  },

  async createOrder(payload: OrderPayload): Promise<{
    status: boolean;
    data: { insertId: number; message?: string };
  } | null> {
    try {
      const response = await (
        await httpClient.post(`orders/order`, payload)
      ).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async patchOrder(payload: OrderPayload, orderId: number): Promise<{
    status: boolean;
    data: { insertId: number; message?: string };
  } | null> {
    try {
      const response = await (
        await httpClient.patch(`orders/order`, {...payload, orderId})
      ).data;
      return response;
    } catch (e) {
      return null;
    }
  },
  async getOrderById(id: number): Promise<{
    status: boolean;
    data: { order: OrderResponse; products: Array<IProduct> };
  } | null> {
    try {
      const response = await (await httpClient.get(`orders/order/${id}`)).data;
      return response;
    } catch (e) {
      return null;
    }
  },
};
export default orders;
