import React from "react";
import { ErrorPage } from "../components/@shared/ErrorPage/ErrorPage";
import { RouteObject, Navigate } from "react-router-dom";
import { AppRouteProtect } from "../components/@shared/AppRouteProtect/AppRouteProtect";

const Orders = React.lazy(() => import("../components/Orders"));
const Home = React.lazy(() => import("../components/Home"));
const SignIn = React.lazy(() => import("../components/SignIn/SignIn"));
const Products = React.lazy(() => import("../components/Products"));

const appRouter: Array<RouteObject & { name?: string }> = [
  {
    path: "/",
    element: <Navigate to="/home" replace />,
  },
  {
    path: "/home",
    element: (
      <AppRouteProtect>
        <Home />
      </AppRouteProtect>
    ),
    name: "Головна",
    errorElement: <ErrorPage />,
  },
  {
    path: "/orders",
    element: (
      <AppRouteProtect>
        <Orders />
      </AppRouteProtect>
    ),
    name: "Замовлення",
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign",
    element: <SignIn />,
    name: "Вхід",
    errorElement: <ErrorPage />,
  },
  {
    path: "/products",
    element: (
      <AppRouteProtect>
        <Products />
      </AppRouteProtect>
    ),
    name: "Товари",
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <ErrorPage />,
    name: "Сторінку не знайдено.",
    errorElement: <ErrorPage />,
  },
];

export { appRouter };
